$base-theme: Default;
$skin-name: U Group Dashboard;
$swatch-name: Moonlight;
$border-radius: 2px;
$accent: #3d4c67;
$text-color: #000;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;


.k-grid td {
  text-align: right !important;
}

.k-grid td, .k-grid th {
  font-weight: 500 !important;
}

tr {
  text-align: center;
}

.k-i-sort-desc-sm::before,
.k-i-sort-asc-sm::before,
.k-sort-order {
  color: #c4c4c4 !important;
}

//// Dataviz
@import "~@progress/kendo-theme-default/scss/dataviz";
@import "~@progress/kendo-theme-default/scss/map/_index.scss";
@import "~@progress/kendo-theme-default/scss/dataviz/_variables.scss";

// Excel Table
@import "~@progress/kendo-theme-default/scss/grid";
@import "~@progress/kendo-theme-default/scss/gantt/_variables.scss";
@import "~@progress/kendo-theme-default/scss/gantt/_layout.scss";


.rs-picker-menu .rs-picker-search-bar::after {
  top: 55% !important;
  transform: translateY(-80%) !important;
}

.rs-picker-menu {
  z-index: 0;
}
