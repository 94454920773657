@font-face {
  font-family: 'DIN';
  src: local('DIN'), url('./fonts/DIN-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Readex Pro';
  src: local('Readex Pro'), url('./fonts/Readex-Pro.ttf') format('ttf');
}

body {
  margin: 0;
  font-family: 'Readex Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h6 {
  color: #646464;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.35);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.google-visualization-orgchart-node {
  font-family: 'Readex Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.MuiFormLabel-root {
  margin-top: 20px;
  font-family: 'Readex Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.k-input,
.k-button {
  font-weight: 500;
}

.k-textarea > .k-input {
  font-weight: 500;
}
