@import '../../../../../../App';

/* match breakpoint with rsuite
xs, extra-small: < 480px
sm, small: ≥ 480px
md, medium: ≥ 992px
lg, large: ≥ 1200px
*/

$antdGrey: #f0f2f5;
$large-width: 1200px;
$medium-width: 992px;

@mixin screen-medium {
  @media (max-width: #{$large-width}) {
    @content;
  }
}

@mixin screen-small {
  @media (max-width: #{$medium-width}) {
    @content;
  }
}

div.orgchart {
  background-image: none;
  display: flex;
  justify-content: center;
  width: inherit;
  padding: 5px
}

div.orgchart-container {
  background-color: transparent;
  border: none;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 400px;
}

div.orgchart ul li .oc-node {
  padding: 16px;
  outline: 0;
  flex: 1 1 auto;
  box-sizing: border-box;
  border-radius: 8px;
  border-width: 1px;
  background-color: #{$antdGrey};
  font-family: inherit;
  font-weight: 500;

  border-left: 4px solid white;
  border-right: 4px solid white;

  &:hover {
    background-color: #{$antdGrey};
  }

  &.selected {
    background-color: #{$antdGrey};
  }

  width: 9vw;
  @include screen-small {
    width: 14vw;
  }
;
}

//
//div.orgchart ul li .oc-node .k-card-title {
//  font-size: 15px;
//}
//
//div.orgchart ul li .oc-node:not(:only-child)::after {
//  background-color: $info;
//}
//
//div.orgchart > ul > li > ul li::before {
//  background-color: $info;
//  border-top: 2px solid $info;
//}
//
//div.orgchart > ul > li > ul li > .oc-node::before {
//  background-color: $info;
//}
//
