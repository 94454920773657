@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';


// rsuite settings
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e5e5ea;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bolder;
  color: inherit;
  margin: 0;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #c5c6c7;
}

h1 {
  font-size: 47px;
  line-height: 62px;
}

h2 {
  font-size: 37px;
  line-height: 50px;
}

h3 {
  font-size: 29px;
  line-height: 42px;
}

h4 {
  font-size: 23px;
  line-height: 34px;
}

h5 {
  font-size: 19px;
  line-height: 24px;
}

h6 {
  font-size: 17px;
  line-height: 22px;
}

span, div, text, p {
  font-size: 16px;
}

span[class*="anticon"] {
  font-size: inherit !important;
}

p {
  margin: 0;
}

p + p {
  margin-top: 8px;
}

small {
  font-size: 85%;
}


// antd
#root {
  width: 100%;
  height: 100%;
  margin: 0;
}

svg:not(:root) {
  overflow: visible;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

// tooltip
.ant-tooltip-arrow {
  display: none;
}

.ant-tooltip-inner {
  border-radius: 6px;
}

// navigation bar
.ant-menu-title-content, .ant-dropdown-menu-title-content {
  font-weight: 500;
}

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0;
}

.ant-divider-inner-text {
  padding: 0;
}

// modal
.ant-modal {
  padding-bottom: 0;
}

.ant-modal-wrap, .ant-modal-mask {
  z-index: 1100;
}

.ant-modal-body {
  border-radius: 6px 6px 0 0;
  background-color: #f0f2f5;
  padding-bottom: 1px;
  padding-top: 16px;
  font-weight: 500;
}

.ant-modal-content > .ant-modal-footer > button.ant-btn.ant-btn-primary {
  background-color: #188ECB;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-modal-content > div.ant-modal-footer > .ant-btn.ant-btn-text {
  font-size: 16px;
  height: 40px;
  color: #9B9B9B;
  font-weight: 500;

  &:hover {
    color: #404040;
  }
}

.ant-modal-footer {
  background-color: #f0f2f5;
  padding: 24px;
  padding-top: 8px;
  padding-bottom: 16px;

}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-left: 0;
}

.ant-modal-title {
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 30px;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal-header {
  border-radius: 6px 6px 0 0;
}

.ant-modal-footer {
  border-radius: 0 0 6px 6px;

}

// select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #EBEBEB;
  border-radius: 4px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 700;
}

.bg-white .ant-select-selector {
  background-color: white !important;
  border-radius: 4px;
}

// skeleton
.ant-skeleton-element {
  width: 100%;
}


// table
.ant-table-cell {
  text-align: left;
}

.ant-table-container table > thead > tr:first-child th:nth-last-child(3), .ant-table-container table > thead > tr:first-child th:nth-last-child(2) {
  text-align: center;
}

// form label
.ant-form-item-label > label::after {
  content: '';
}

// custom period
.custom-period-summary {
  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: auto;
  }

  .ant-timeline-item-last {
    padding-bottom: 0 !important;
  }
}
