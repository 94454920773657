@import "src/App";

.k-panelbar > .k-item > .k-link {
  &:hover {
    cursor: pointer;
  }
}


#custom-group-name-input::placeholder {
  color: grey
}


.k-step-indicator {
  &:hover {
    cursor: pointer;
  }
}

.k-step-success > .k-step-link > .k-step-indicator {
  border-color: $success !important;
  background-color: $success !important;
}

.k-step-error > .k-step-link > .k-step-indicator {
  border-color: $error !important;
  background-color: $error !important;
}

.k-progressbar .k-state-selected {
  background-color: green !important;
}
