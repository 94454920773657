@import '../../../rsuite/MultiCascader/core/Dropdown/styles/mixin';

// Picker Menu items common styles
.picker-menu-items-reset() {
  // Set aside border-radius borders
  margin-bottom: @border-radius-base;
  overflow-y: auto;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

// Picker Menu group common styles
.picker-menu-group-common(@prefix) {
  // Set border & padding to menu group if  it isn't the first.
  .@{ns}@{prefix}-menu-group:not(:first-child) {
    margin-top: @picker-group-select-menu-gap;
    border-top: 1px solid @picker-group-select-menu-lin-color;
    padding-top: @picker-group-select-menu-gap;
  }
}

// Group title common styles
.picker-menu-group-title(@prefix) {
  .@{ns}@{prefix}-menu-group-title {
    padding: @picker-item-content-padding-vertical @picker-item-content-padding-horizontal;
    padding-right: (@padding-base-horizontal + @dropdown-caret-width + @dropdown-caret-padding);
    position: relative;
    cursor: default;
    transition: @picker-item-transition;
    color: @picker-group-title-color;

    &:hover {
      background-color: @picker-menu-item-hover-bg;
    }

    .@{ns}@{prefix}-menu-group-caret {
      display: inline-block;
      margin-left: 2px;
      position: absolute;
      top: @padding-base-vertical;
      right: @padding-base-horizontal;
      transition: transform 0.3s linear;
      padding: 3px;
    }
  }
}

// Group closed common styles
.picker-menu-group-closed(@prefix) {
  .@{ns}@{prefix}-menu-group.folded {
    .@{ns}@{prefix}-menu-group-caret {
      transform: rotate(90deg);
    }
  }
}

.picker-select-item() {
  display: block;
  padding: @picker-item-content-padding-vertical @picker-item-content-padding-horizontal;
  clear: both;
  font-weight: normal;
  line-height: @line-height-base;
  color: @picker-select-item-color;
  cursor: pointer; //fixed link has not [href] style
  transition: @picker-item-transition;
  text-decoration: none;
  width: 100%;

  /* stylelint-disable-next-line */ // This class name provide by Virtualized.
  .ReactVirtualized__Grid & {
    .ellipsis-basic();
  }
}

.picker-item-hover() {
  color: @picker-select-item-color;
  background-color: @picker-menu-item-hover-bg;
}

.picker-item-active() {
  font-weight: @picker-select-menu-item-selected-font-weight;
}

.picker-calendar-cell-content() {
  display: inline-block;
  font-size: @calendar-table-cell-content-font-size;
  line-height: @calendar-table-cell-content-line-height;
  padding: @calendar-table-cell-content-padding;
  cursor: pointer;
  border-radius: @border-radius-base;

  &:hover {
    background-color: @calendar-table-cell-content-hover-bg;
  }
}

.picker-calendar-cell-content-selected() {
  color: @calendar-table-cell-content-selected-font-color;
  border-color: @calendar-table-cell-content-selected-color;
  background-color: @calendar-table-cell-content-selected-color;
}

.picker-calendar-title-show() {
  color: @picker-calendar-title-color;
  background: transparent;
}

.picker-custom-button-caret-size(@padding-horizontal,@padding-vertical,@clean-btn-margin:2px) {
  @clean-btn-width: @dropdown-caret-width;

  .@{ns}picker-toggle-wrapper & {
    padding-right: (@padding-horizontal + @dropdown-caret-width + @dropdown-caret-padding);
  }

  .@{ns}picker-cleanable & {
    padding-right: (
      @padding-horizontal + @dropdown-caret-width + @dropdown-caret-padding + @clean-btn-width + @clean-btn-margin
    );
  }

  .@{ns}picker-toggle-caret,
  .@{ns}picker-toggle-clean {
    top: @padding-vertical;

    .@{ns}picker-default & {
      top: @padding-vertical - @picker-default-toggle-border-width;
    }
  }

  .@{ns}picker-toggle-caret {
    right: @padding-horizontal;
  }

  .@{ns}picker-toggle-clean {
    right: (@dropdown-caret-width + @padding-horizontal + @clean-btn-width - @clean-btn-margin);
  }
}

.picker-button-caret-lg() {
  .picker-custom-button-caret-size(
          @padding-large-horizontal,
          @padding-large-vertical,
    @clean-btn-margin:4px
  );
}

.picker-button-caret-md() {
  .picker-custom-button-caret-size(@padding-base-horizontal, @padding-base-vertical);
}

.picker-button-caret-sm() {
  .picker-custom-button-caret-size(@padding-small-horizontal, @padding-small-vertical);
}

.picker-button-caret-xs() {
  .picker-custom-button-caret-size(
          @padding-extra-small-horizontal,
          @padding-extra-small-vertical,
    @clean-btn-margin:2px
  );
}

.daterange-picker-custom-toggle(@calendar-caret-width: 14px,@padding-horizontal, @top, @clean-btn-margin:2px) {
  @toggle-clean-padding: 4px;
  @clean-btn-width: @dropdown-caret-width;

  padding-right: (@padding-horizontal + @calendar-caret-width + @dropdown-caret-padding);

  .@{ns}picker-toggle-clean,
  .@{ns}picker-toggle-caret {
    top: @top;

    .@{ns}picker-subtle & {
      top: @top - @picker-default-toggle-border-width;
    }
  }

  .@{ns}picker-toggle-caret {
    right: @padding-horizontal;
  }

  .@{ns}picker-toggle-clean {
    right: (@padding-horizontal + @toggle-clean-padding + @calendar-caret-width);
  }
}

.date-picker-button-caret-lg() {
  .@{ns}picker-date &,
  .@{ns}picker-daterange & {
    .daterange-picker-custom-toggle(
      @calendar-caret-width: @font-size-large,
            @padding-large-horizontal,
      @top: 10px,
      @clean-btn-margin: 4px
    );
  }
}

.date-picker-button-caret-md() {
  .@{ns}picker-date &,
  .@{ns}picker-daterange & {
    .daterange-picker-custom-toggle(
      @calendar-caret-width: @font-size-base,
            @padding-base-horizontal,
      @top: 8px
    );
  }
}

.date-picker-button-caret-sm() {
  .@{ns}picker-date &,
  .@{ns}picker-daterange & {
    .daterange-picker-custom-toggle(
      @calendar-caret-width: @font-size-base,
            @padding-small-horizontal,
      @top: 4px
    );
  }
}

.date-picker-button-caret-xs() {
  .@{ns}picker-date &,
  .@{ns}picker-daterange & {
    .daterange-picker-custom-toggle(
      @calendar-caret-width: @font-size-extra-small,
            @padding-extra-small-horizontal,
      @top: 2px
    );
  }
}

.date-picker-button-size(@size) {
  @padding-name: 'padding-@{size}-vertical';
  @padding-vertical: @@padding-name;

  .@{ns}picker-default & {
    .padding-vertical(@padding-vertical - @picker-default-toggle-border-width);
  }
}

.picker-default-button-reset-padding-left(@size) {
  @padding-name: 'padding-@{size}-horizontal';
  @padding-horizontal: @@padding-name;

  .@{ns}picker-default & {
    padding-left: @padding-horizontal - @picker-default-toggle-border-width;
  }
}

.tag-picker-search-input-size(@padding-vertical; @padding-horizontal; @font-size; @line-height;) {
  .@{ns}picker-input:not(.@{ns}picker-tag) & ~ .@{ns}picker-tag-wrapper .@{ns}picker-search-input {
    padding: (@padding-vertical - 1px) @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
  }

  .@{ns}picker-input.@{ns}picker-tag & ~ .@{ns}picker-tag-wrapper {
    padding-bottom: @padding-vertical - 3px;

    .@{ns}tag {
      margin-top: @padding-vertical - 3px;
    }

    .@{ns}picker-search-input {
      padding-left: @padding-horizontal;
      margin-top: @padding-vertical - 3px;
      font-size: @font-size;
    }

    .@{ns}picker-search-input input {
      height: @line-height * @font-size - 2px;
    }
  }

  .@{ns}picker-input.@{ns}picker-tag & {
    padding-top: @padding-vertical - 1px;
    padding-bottom: @padding-vertical - 1px;
    box-shadow: none;
  }
}

.tag-picker-search-input-lg() {
  .tag-picker-search-input-size(
          @padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large
  );
}

.tag-picker-search-input-md() {
  .tag-picker-search-input-size(
          @padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base
  );
}

.tag-picker-search-input-sm() {
  .tag-picker-search-input-size(
          @padding-small-vertical; @padding-small-horizontal; @font-size-base; @line-height-base
  );
}

.tag-picker-search-input-xs() {
  /* stylelint-disable */ //Formatted by prettier
  .tag-picker-search-input-size(
          @padding-extra-small-vertical; @padding-extra-small-horizontal; @font-size-extra-small; @line-height-extra-small
  );
  /* stylelint-enable */

  .@{ns}picker-input.@{ns}picker-tag & ~ .@{ns}picker-tag-wrapper {
    padding-bottom: @padding-extra-small-vertical - 1px;

    .@{ns}tag {
      margin-top: 1px;
    }

    // Adjust search-input size the same with button
    .@{ns}picker-search-input {
      .padding-vertical(1px);
    }
  }
}

.input-picker-input-sizes() {
  @border-width: 2 * @picker-default-toggle-border-width;

  .@{ns}picker-toggle.@{ns}btn-lg {
    height: (@line-height-large-computed + @padding-large-vertical*2 - @border-width);
  }

  .@{ns}picker-toggle.@{ns}btn-md {
    height: (@line-height-computed + @padding-base-vertical*2 - @border-width);
  }

  .@{ns}picker-toggle.@{ns}btn-sm {
    height: (@line-height-computed + @padding-small-vertical*2 - @border-width);
  }

  .@{ns}picker-toggle.@{ns}btn-xs {
    height: (@line-height-computed + @padding-extra-small-vertical*2 - @border-width);
  }
}

// Default picker toggle
.picker-default-toggle() {
  .@{ns}picker-default & {
    .dropdown-toggle();

    color: @picker-default-toggle-font-color;
    border: @picker-default-toggle-border-width solid @picker-default-toggle-border-color;
  }

  .@{ns}picker-disabled & {
    cursor: @cursor-disabled;
  }

  .@{ns}picker-default:not(.@{ns}picker-disabled) {
    &:hover,
    &:focus {
      border-color: @picker-default-toggle-hover-border-color;
    }
  }
}

// Subtle picker toggle
.picker-subtle-toggle {
  .@{ns}picker-subtle & {
    .dropdown-toggle();
  }

  .@{ns}picker-subtle.@{ns}picker-disabled & {
    cursor: @cursor-disabled;

    &:hover,
    &:focus,
    &:active {
      background: none;

      &::after {
        display: none;
      }
    }
  }
}
